<template>
  <div class="schmenu_layer">
    <!-- 검색 추가 layer -->
    <div class="div_title">
      <button
        class="layer_close"
        @click="$emit('close')"
      >
        {{ $t('msg.CMBK100_M1.038') }}
      </button>
      <br>
      {{ $t('msg.UTIL010G010.005') }}<!-- 메뉴를 선택하시면 입력하신 번호가 조회됩니다. -->
    </div>
    <ul class="munu_list">
      <li
        v-for="menu in parentInfo.menuList"
        :key="menu.routerName"
      >
        <a
          @click.prevent="$emit('close', { searchType: parentInfo.searchType, keyword: parentInfo.keyword, eiCatCd: parentInfo.eiCatCd, bgRouteName: parentInfo.bgRouteName, menu: menu })"
        >{{ menu.menuName }}</a>
      </li>
    </ul>
  </div> <!-- 검색 추가 layer // -->
</template>

<script>
import { rootComputed } from '@/store/helpers'

export default {
  name: 'NoSearchResultPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          searchType: '',
          keyword: '',
          menuList: [],
          bgRouteName: ''
        }
      }
    }
  },
  created () {
    this.changeMenuName()
  },
  computed: {
    ...rootComputed
  },
  methods: {
    changeMenuName () {
      if (this.auth.serviceLang !== 'KOR') {
        this.parentInfo.menuList.forEach(ele => {
          switch (ele.routerName) {
            case 'progress':
              ele.menuName = 'Ongoing'
              break
            case 'calcIssueMain':
              ele.menuName = 'Settlement/Issue - Issue'
              break
            case 'FreeTimeRequest':
              ele.menuName = 'Settlement/Issue - Freetime Request'
              break
            case 'cargo-tracking':
              ele.menuName = 'Cargo Tracking'
              break
          }
        })
      }
    }
  }
}
</script>

<style scope>
  .schmenu_layer .munu_list li a:hover { background:#F2F9FE; color: #000 !important; cursor: pointer;}
</style>
