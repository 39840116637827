var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "schmenu_layer" }, [
    _c("div", { staticClass: "div_title" }, [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("msg.CMBK100_M1.038")) + " ")]
      ),
      _c("br"),
      _vm._v(" " + _vm._s(_vm.$t("msg.UTIL010G010.005"))),
    ]),
    _c(
      "ul",
      { staticClass: "munu_list" },
      _vm._l(_vm.parentInfo.menuList, function (menu) {
        return _c("li", { key: menu.routerName }, [
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("close", {
                    searchType: _vm.parentInfo.searchType,
                    keyword: _vm.parentInfo.keyword,
                    eiCatCd: _vm.parentInfo.eiCatCd,
                    bgRouteName: _vm.parentInfo.bgRouteName,
                    menu: menu,
                  })
                },
              },
            },
            [_vm._v(_vm._s(menu.menuName))]
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }